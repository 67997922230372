<template>
    <div class="app-header">
        <nav class="navbar navbar-light navbar-expand-lg">
            <div class="container-fluid">
                <div class="navbar-nav" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link hide-sidebar-toggle-button" href="#"><i class="material-icons">arrow_back_ios</i></a>
                        </li>
                        <!-- <li>
                            <select v-if="clients !== null" class="form-select">
                                <option value="currentClient.id" selected> {{currentClient.name}} </option>
                                <option v-for="client in clients" :key="'client' + client.id" :value="client.id"> {{client.name}} </option>
                            </select>
                        </li> -->
                        <!-- ANCHOR Add Dropdown -->
                        <!-- <li class="nav-item dropdown hidden-on-mobile">
                            <a class="nav-link dropdown-toggle" href="#" id="addDropdownLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="material-icons">add</i>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="addDropdownLink">
                                <li><a class="dropdown-item" href="#">New Workspace</a></li>
                                <li><a class="dropdown-item" href="#">New Board</a></li>
                                <li><a class="dropdown-item" href="#">Create Project</a></li>
                            </ul>
                        </li> -->
                        <!-- ANCHOR Explore Dropdown -->
                        <!-- <li class="nav-item dropdown hidden-on-mobile">
                            <a class="nav-link dropdown-toggle" href="#" id="exploreDropdownLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="material-icons-outlined">explore</i>
                            </a>
                            <ul class="dropdown-menu dropdown-lg large-items-menu" aria-labelledby="exploreDropdownLink">
                                <li>
                                    <h6 class="dropdown-header">Repositories</h6>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <h5 class="dropdown-item-title">
                                            Neptune iOS
                                            <span class="badge badge-warning">1.0.2</span>
                                            <span class="hidden-helper-text">switch<i class="material-icons">keyboard_arrow_right</i></span>
                                        </h5>
                                        <span class="dropdown-item-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <h5 class="dropdown-item-title">
                                            Neptune Android
                                            <span class="badge badge-info">dev</span>
                                            <span class="hidden-helper-text">switch<i class="material-icons">keyboard_arrow_right</i></span>
                                        </h5>
                                        <span class="dropdown-item-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                    </a>
                                </li>
                                <li class="dropdown-btn-item d-grid">
                                    <button class="btn btn-primary">Create new repository</button>
                                </li>
                            </ul>
                        </li> -->
                    </ul>
    
                </div>
                <div class="d-flex">
                    <ul class="navbar-nav">
                        <!-- <router-link custom to="/settings" v-slot="{isExactActive}">
                            <li class="nav-item hidden-on-mobile">
                                <a class="nav-link" :class="[!isExactActive && 'active']" href="javascript:void(0);" @click="$router.push('/')">{{$t('navigation.top.applications')}}</a>
                            </li>
                        </router-link> -->
                        <!-- <router-link custom to="/settings" v-slot="{isExactActive}">
                            <li class="nav-item hidden-on-mobile" :class="[isExactActive && 'active']">
                                <a class="nav-link" :class="[isExactActive && 'active']" href="javascript:void(0);" @click="$router.push('/settings')">{{$t('navigation.top.settings')}}</a>
                            </li>
                        </router-link> -->
                        
                        <li class="nav-item hidden-on-mobile">
                            <a class="nav-link" href="javascript:void(0)" @click="handleLogout">{{$t('navigation.top.logout')}}</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link toggle-search" href="#"><i class="material-icons">search</i></a>
                        </li> -->
                        <li class="nav-item" style="height: 100%;">
                            <button v-if="!night" class="btn btn-sm" style="color: black; height: 100%;" @click="$store.dispatch('auth/changeMode', !night)"><span class="material-icons" style="line-height: 1.425; color: #56595d;" title="Dark Mode">dark_mode</span></button>
                            <button v-else class="btn btn-sm" style="color: white; height: 100%;" @click="$store.dispatch('auth/changeMode', !night)"><span class="material-icons" style="line-height: 1.425;" title="Light Mode">light_mode</span></button>
                        </li>
                        <!-- ANCHOR Language Dropdown -->
                        <li class="nav-item hidden-on-mobile">
                            <a class="nav-link language-dropdown-toggle" href="javascript:void(0);" id="languageDropDown" data-bs-toggle="dropdown"><img :src="`/images/flags/${$i18n.locale}.png`" alt=""></a>
                            <ul class="dropdown-menu dropdown-menu-end language-dropdown" style="overflow-y: hidden;" id="languageDropdown" aria-labelledby="languageDropDown">
                                <li><a class="dropdown-item" href="javascript:void(0);" @click="changeLocale('en')"><img src="../../../public/images/flags/en-GB.png" alt="">{{ $t('languages.english') }}</a></li>
                                <!-- <li><a class="dropdown-item" href="javascript:void(0);" @click="changeLocale('de')"><img src="/images/flags/de.png" alt="">German</a></li> -->
                                <li><a class="dropdown-item" href="javascript:void(0);" @click="changeLocale('it')"><img src="../../../public/images/flags/it.png" alt="">{{ $t('languages.italian') }}</a></li>
                            </ul>
                        </li>
                        
                        <!-- <li class="nav-item hidden-on-mobile">
                            <a class="nav-link nav-notifications-toggle" id="notificationsDropDown" href="#" data-bs-toggle="dropdown">4</a>
                            <div class="dropdown-menu dropdown-menu-end notifications-dropdown" aria-labelledby="notificationsDropDown">
                                <h6 class="dropdown-header">Notifications</h6>
                                <div class="notifications-dropdown-list">
                                    <a href="#">
                                        <div class="notifications-dropdown-item">
                                            <div class="notifications-dropdown-item-image">
                                                <span class="notifications-badge bg-info text-white">
                                                    <i class="material-icons-outlined">campaign</i>
                                                </span>
                                            </div>
                                            <div class="notifications-dropdown-item-text">
                                                <p class="bold-notifications-text">Donec tempus nisi sed erat vestibulum, eu suscipit ex laoreet</p>
                                                <small>19:00</small>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div class="notifications-dropdown-item">
                                            <div class="notifications-dropdown-item-image">
                                                <span class="notifications-badge bg-danger text-white">
                                                    <i class="material-icons-outlined">bolt</i>
                                                </span>
                                            </div>
                                            <div class="notifications-dropdown-item-text">
                                                <p class="bold-notifications-text">Quisque ligula dui, tincidunt nec pharetra eu, fringilla quis mauris</p>
                                                <small>18:00</small>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div class="notifications-dropdown-item">
                                            <div class="notifications-dropdown-item-image">
                                                <span class="notifications-badge bg-success text-white">
                                                    <i class="material-icons-outlined">alternate_email</i>
                                                </span>
                                            </div>
                                            <div class="notifications-dropdown-item-text">
                                                <p>Nulla id libero mattis justo euismod congue in et metus</p>
                                                <small>yesterday</small>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div class="notifications-dropdown-item">
                                            <div class="notifications-dropdown-item-image">
                                                <span class="notifications-badge">
                                                    <img src="/images/avatars/avatar.png" alt="">
                                                </span>
                                            </div>
                                            <div class="notifications-dropdown-item-text">
                                                <p>Praesent sodales lobortis velit ac pellentesque</p>
                                                <small>yesterday</small>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div class="notifications-dropdown-item">
                                            <div class="notifications-dropdown-item-image">
                                                <span class="notifications-badge">
                                                    <img src="/images/avatars/avatar.png" alt="">
                                                </span>
                                            </div>
                                            <div class="notifications-dropdown-item-text">
                                                <p>Praesent lacinia ante eget tristique mattis. Nam sollicitudin velit sit amet auctor porta</p>
                                                <small>yesterday</small>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import UserService from '../../services/user.service';

export default {
    name: 'VHeader',
    data(){
        return {
            ps: null,
            cps: null
        }
    },
    mounted(){
      this.ps = new PerfectScrollbar('#languageDropdown');
    },
    methods: {
        handleLogout(){
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.replace('/login');
            });
        },
        selectClient(client){
            UserService.selectClient(client).then(() => {
                this.$store.dispatch('auth/selectClient', client).then(() => {
                    this.$router.go(this.$router.currentRoute);
                })
            })
            .catch(error => {
                // Handle error
                // console.error(error);
            });
            
        },
        changeLocale(locale){
          this.$i18n.locale = locale;
        }
    },
    computed: {
        clients: function(){
            return this.$store.state.auth.user.clients.sort((a,b) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
        },
        currentClient: function(){
            return this.$store.state.auth.user.clients.filter((c) => {
                return parseInt(c.id) === parseInt(this.$store.state.auth.user.selectedClient);
            })[0];
        },
        settings: function(){
            return this.$router.currentRoute._value.name === 'settings';
        },
        night(){
            return this.$store.state.auth.status.dark;
        }
    },
    watch: {
        night: function(n, o){
            this.$store.dispatch('auth/changeMode', n);
            let existingCss = document.getElementById('darkThemeLink');
            console.log(existingCss);
            if(n === true && (existingCss === undefined || existingCss === null)){
                let file = document.createElement('link');
                file.id = 'darkThemeLink';
                file.rel = 'stylesheet';
                file.href = '/css/darktheme.css'
                document.head.appendChild(file)
            }
            if(n === false && (existingCss !== undefined && existingCss !== null)){
                existingCss.remove();
            }
        }
    }
}
</script>

<style scoped>
.language-dropdown {
    max-height: 50vh;
    overflow-y: scroll;
}
.initials:before {
    background-color: green;
    color: white;
    opacity: 1; 
    content: attr(data-initials); 
    display: inline-block; 
    font-weight: bold; 
    border-radius: 50%; 
    vertical-align: middle; 
    margin-right: 0.5em; 
    width: 35px; 
    height: 35px; 
    line-height: 35px; 
    text-align: center; 
}
.initials {
    display: inline-block;
    width: fit-content;
}
</style>