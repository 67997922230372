import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class UserService {
    getUsers() {
        return axios.get(`${API_URL}users/list`, {headers: authHeader()});
    }

    getUser() {
        return axios.get(API_URL + 'auth/login', {headers: authHeader()})
    }

    selectClient(client){
        return axios.patch('https://server.varsanpr.com/api/account/changeclient', {client_id: client.id}, {headers: authHeader()})
    }

    getDashboard(client){
        return axios.get(API_URL + 'dashboard?client_id=' + client, {headers: authHeader()})
    }
}

export default new UserService();